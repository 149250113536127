import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/greg/Desktop/projects/portfolio/src/components/page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This project is my main focus outside of work and family these days. I started
on the idea when I had a few months off in between leaving `}<a parentName="p" {...{
        "href": "/qintel"
      }}>{`qintel`}</a>{` and
joining `}<a parentName="p" {...{
        "href": "/vic"
      }}>{`vic`}</a>{`. The problem we're trying to solve is pretty simple; while
there's a ton of great open source political and societal data out there, very
little of it is easily accessible to folks who aren't technical. And, even for
those who are technical, it's painful to aggregate and clean it enough such
that it can be made presentable and intuitive.`}</p>
    <p>{`So at least to start, we're kind of a data aggregator with free features like
exports and (soon) embeds that we hope will prove useful to educators,
journalists and anyone else in the space. Long term though, we hope to provide
crowd-sourcing functionality that allows our community to easily fill in gaps
or even submit larger blocks of data.`}</p>
    <h2>{`why politics?`}</h2>
    <p>{`My extended family, as far as I can remember, have always been political
junkies. It always bored me hearing them talk about it as a kid, but once I got
out of college and started my career I began to get a little more intrigued by
government.`}</p>
    <p>{`What interests me more than any particular leader, party or issue is the
architecture of government. This includes its overall structure but also how
our leaders interface with their consituents. Like many young -- and I guess
some older -- people these days, a lot of what I observed felt quite clunky. I
think this is a natural first instinct many outsiders experience when they're
first introduced to a field. For example, when I first became an engineer a
lot of the process companies enforced felt like overkill but now that I've been
in the game for a while and learned the rationale for some of it, I reject it
less.`}</p>
    <p>{`However there are some aspects of government that, even after more research and
understanding still feel pretty silly to me...`}</p>
    <ul>
      <li parentName="ul">{`Bills are often gigantic, in an attempt to fix too many problems at once.`}</li>
      <li parentName="ul">{`There's an insane amount of money flying around between campaigns and lobbying.`}</li>
      <li parentName="ul">{`We rarely talk about the cause and effect, hyper-focusing on our current
problems but less so on the trail of decisions that got us to those places.`}</li>
    </ul>
    <h2>{`intersection with software`}</h2>
    {
      /* maybe a separate article 
      The first of those problems listed above is one of the most interesting to me
      because it parallels a similar problem that the tech industry has grappled with
      in the past; managing change at scale. When you're working on any large product
      with lots of consumers, the "grand reveal" -- meaning a large change -- can be
      quite tempting as its an "easier" initial sale. The problem with that mentality
      however is that larger changes take more time, and time is often critical.
      With a little thought, most projects can be broken up into smaller increments
      where each one is quicker to achieve and still delivers value. While this isn't
      always as glamorous as one grand reveal, it puts value into customer's hands
      faster and speeds up feedback loops. It also makes changes to the project
      easier for the team to manage because they are inheritently smaller, and --
      when done right -- each is an accomplishment that boosts morale for the next.
      While some larger companies still do plenty of grand reveals, most of the
      software industry has learned this lesson with practices like [feature flags],
      [agile development] and [smaller branches]. The implementation would be
      different, but I feel like many of the same ideas could carry over to politics
      and government.
      */
    }
    <p>{`I think that some of these problems can be solved with software. What if there
were a place where citizens could more easily visualize how their country is
doing and how their government's actions have played into that? What if we
could see those things across time and compare our country's performance
to another country's? And what if citizens could engage more with each key
decision that's being made, not necessarily as a direct democracy, but at least
to a point where a representative would have a very clear picture of their
consituents' thoughts on the matter.`}</p>
    <p>{`The technology to build such a platform certainly exists. In fact, the tech
industry is solving much harder problems like artificial intelligence,
biometrics and mugs that keep your coffee warm forever. As noted above, much
of the needed data is also out there but hasn't been tapped in more meaningful
ways yet. What's not there can either be crowdsourced or -- ideally -- exposed
by the government in question.`}</p>
    <h2>{`so why not build it?`}</h2>
    <p>{`As you might've guessed at this point, I was leading up to `}<a parentName="p" {...{
        "href": "https://projectseldon.org"
      }}>{`some reveal`}</a>{`. We
still have `}<a parentName="p" {...{
        "href": "https://github.com/project-seldon/intake"
      }}>{`a lot of work to do`}</a>{`, but you can already...`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://projectseldon.org/explore"
        }}>{`Explore the world as a whole.`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://projectseldon.org/explore?country=us"
        }}>{`Dive into a specific country.`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://projectseldon.org/learn/basics#export"
        }}>{`Export data from basically anywhere in the application.`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://projectseldon.org/search"
        }}>{`Search for politicians across multiple countries.`}</a></li>
    </ul>
    <p>{`Now that we have some key parts of the foundation laid, we're shifting our
focus to building out the community and adding features, like embeds, that
empower users more and more with each new block of data that's added. If you
have ideas please `}<a parentName="p" {...{
        "href": "https://github.com/project-seldon/intake"
      }}>{`submit them here`}</a>{` or email me at `}<a parentName="p" {...{
        "href": "mailto:greg.venech@gmail.com"
      }}>{`greg.venech@gmail.com`}</a>{`
if that's easier for you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      